import * as views from '../../views/views-list';
import { removeAllHtml } from '../../utils/utils';

const getLoggedUserId = (state) => {
  const { userInfo: { systemUserId = '' } = {} } = state;
  return systemUserId;
};

const getInitialRecipients = (state) => {
  const { initialRecipients = [] } = state;
  return initialRecipients;
};

export const setGiveOptions = (state, payload) => {
  if (payload && payload.error && payload.error.indexOf('no give eligibility') > -1) {
    return {
      isGiveEnabled: false
    };
  }
  return {
    isGiveEnabled: true,
    termsOfService: payload.termsOfService,
    givePermission: payload.givePermission,
    hasBulkPermission: payload.hasBulkPermission,
    showHelpGuide: payload.showGroupEcardHelp,
    isRequiredToRestrictSingleRecipient: payload.isRequiredToRestrictSingleRecipient
  };
};

export const getUserIdsFromApproverPresenters = (approverIds, presenterIds) => approverIds.concat(presenterIds)
  .filter(({ 'selection-type': selectionType }) => selectionType === 'provided' || selectionType === 'select' || selectionType === 'none')
  .map(({ options = [], value = '' }) => ({ value: value || options.reduce((acc, option) => `${acc},${option.value}`, '').slice(1) }))
  .reduce((acc, { value }) => `${acc},${value}`, '')
  .slice(1);

export const transformedUserData = (userData) => userData.reduce((acc, userDataItem) => {
  const {
    id, name = '', preferName = '', firstName = '', lastName, initials, avatar: { thumb: { src } = {} } = {}
  } = userDataItem;
  return Object.assign(acc, {
    [id]: {
      imageThumbUrl: src,
      initials,
      fullName: removeAllHtml(name),
      preferName: removeAllHtml(preferName),
      firstName,
      lastName
    }
  });
}, {});

export const mergeUserImagesforProvidedSelectionType = (userData = {}, list = []) => list.map((listItem) => {
  const { value = '', 'selection-type': selectionType } = listItem;
  if (selectionType === 'provided' || selectionType === 'none') {
    return { ...listItem, ...userData[value] };
  }
  return listItem;
});

export const mergeUserImagesforSelectSelectionType = (userData = {}, list = []) => list.map((item) => {
  const { 'selection-type': selectionType, options = [] } = item;
  if (selectionType === 'select') {
    const newOptions = options.map((option) => ({ ...option, ...userData[option.value] }));
    return { ...item, options: newOptions };
  }
  return item;
});

export const addMessage = (state, payload) => {
  if (payload.onAction) {
    return state.concat(payload);
  }
  return state.concat({ message: payload });
};

export const setViewNumber = (state, payload) => payload;

export const selectCompiledAvatarImage = ({ thumb = {}, profile = {}, original = {} } = {}) =>
  (thumb.src || profile.src || original.src) ||
  ((typeof thumb === 'string' && thumb) || (typeof profile === 'string' && profile) || (typeof original === 'string' && original)) ||
  '';

export const selectDepartmentName = ({ department: { title = '' } = {} } = {}) => title;
export const selectManagerName = ({ manager = {} } = {}) => manager?.fullName || '';

export const selectCompiledFollowedEmployees = (collection = []) =>
  collection.filter((collectionItem) => (
    collectionItem.user || collectionItem.isActive
  )).map((collectionItem) => {
    let formatUser = collectionItem;
    if (collectionItem.user) {
      formatUser = collectionItem.user;
    }
    return {
      systemUserId: formatUser.id,
      fullName: removeAllHtml(formatUser.fullName),
      firstName: formatUser.firstName,
      preferName: removeAllHtml(formatUser.preferName),
      lastName: formatUser.lastName,
      imageSrc: selectCompiledAvatarImage(formatUser.avatar),
      initials: formatUser.initials,
      departmentName: selectDepartmentName(formatUser),
      managerName: removeAllHtml(selectManagerName(formatUser))
    };
  });

export const transformUserSearchResults = (collection = []) => {
  const showJobTitleAndWorkLocation = collection.some(
    (item) => (item.jobTitle && item.jobTitle.trim() !== '') ||
                (item.workLocation && item.workLocation.trim() !== '')
  );
  return collection.map((collectionItem) => ({
    systemUserId: collectionItem.systemUserId,
    fullName: removeAllHtml(collectionItem.fullName),
    firstName: collectionItem.firstName,
    preferName: removeAllHtml(collectionItem.preferName),
    lastName: collectionItem.lastName,
    imageSrc: selectCompiledAvatarImage(collectionItem.avatar),
    initials: collectionItem.initials,
    departmentName: collectionItem.department,
    managerName: removeAllHtml(selectManagerName(collectionItem)),
    jobTitle: !collectionItem.jobTitle ? '' : collectionItem.jobTitle,
    workLocation: !collectionItem.workLocation ? '' : collectionItem.workLocation,
    showJobTitleAndWorkLocation
  }));
};

export const setFollowedEmployees = (state, { status: { code = '' } = {}, data: { collection = [] } = {} } = {}) => {
  if (code === 200) {
    return selectCompiledFollowedEmployees(collection);
  }
  return [];
};

export const setUserInfo = (state, {
  firstName = '', lastName = '', preferName = '', id = 0
} = {}) => ({
  firstName,
  lastName,
  preferName: removeAllHtml(preferName),
  systemUserId: id
});

export const setRecognitionTypes = (state, { status: { code = '' } = {}, data = {} } = {}) => {
  if (code === 200) {
    const {
      selectRecognitionType: { input: { recognitionType: { options = [] } = {} } = {} } = {},
      selectBulkRecognitionType: { input: { recognitionType: { options: bulkOptions = [] } = {} } = {} } = {}
    } = data;
    return options.concat(bulkOptions).reduce((acc, { value = '' }) => {
      if (value.toLowerCase() === 'ecard') {
        return { ...acc, hasECard: true };
      } else if (value.toLowerCase() === 'nomination') {
        return { ...acc, hasNomination: true };
      }
      return acc;
    }, {});
  }
  const { error: { message = '' } = {} } = data;
  const truncatedMessage = message.substring(message.indexOf(':') + 1);
  return {
    error: true,
    code,
    hasECard: false,
    hasNomination: false,
    message: truncatedMessage
  };
};

export const setSelectedRecognitionType = (state, selectedRecognitionType = '') => selectedRecognitionType;

export const setTransactionId = (state, transactionId = '') => transactionId;

export const setRecipients = (state, { recipients = [] }) => recipients;

export const setInitialRecipients = (state, { initialRecipients = [] }) => initialRecipients;

export const setRecommendedAwardLevel = (state, recommendedAwardLevel = []) => recommendedAwardLevel;

export const setAwardLevel = (state, selectedAwardLevel) => selectedAwardLevel;

export const setPrograms = (
  state,
  { status: { code = '' } = {}, data: { selectProgram: { input: { programId: { options = [] } = {} } = {} } = {} } = {} }
) => {
  if (code === 200) {
    return options.reduce((acc, { text = '', value = '' }) => acc.concat([{ id: parseInt(value, 10), programName: text }]), []);
  }
  return [
    {
      error: true
    }
  ];
};

export const setWizard = (state, wizard = []) => wizard;

export const setWizardFlowFinished = (state, wizardFlowFinished = false) => wizardFlowFinished;

export const setEditRecipient = (state, editRecipient = false) => editRecipient;

export const setWizardOptional = (state, wizardOptional = false) => wizardOptional;

export const setWizardUsed = (state, wizardUsed = false) => wizardUsed;

export const setCorporateValueList = (state, corporateValue = '') => corporateValue;

export const setCCUsers = (state, ccUsers = {}) => ccUsers;

export const setDateFormat = (state, dateFormat = '') => dateFormat && dateFormat.toLowerCase();

export const setApproverList = (state, approverList = []) => approverList;

export const setPresenterList = (state, presenterList = []) => presenterList;

export const setUploadedECard = (state, uploadedECard) => uploadedECard;

export const setDescriptionOfAchievement = (state, descriptionOfAchievement = '') => {
  const defaultDescriptionOfAchievement = {
    disabled: true,
    max: 0,
    required: false
  };
  return descriptionOfAchievement === '' ? defaultDescriptionOfAchievement : descriptionOfAchievement;
};

export const setPrivateEnabledNomination = (state, isPrivacyEnabledNomination = false) => isPrivacyEnabledNomination;

export const setPrivateEnabledECard = (state, isPrivacyEnabledECard = false) => isPrivacyEnabledECard;

export const setECardUploadEnabled = (state, isECardUploadEnabled = false) => isECardUploadEnabled;

// The api isn't sending the thankYouNote sometimes. I'm defaulting it to disabled.
export const setThankYouNote = (state, thankYouNote = '') => {
  const defaultThankYouNote = {
    disabled: true,
    max: 0,
    required: false
  };
  return thankYouNote === '' ? defaultThankYouNote : thankYouNote;
};

export const setBoiCost = (state, boiLabelLine1OverrideField = {}) => boiLabelLine1OverrideField;

// This is the metadata from the back end
export const setNoteToApprover = (state, noteToApprover = {}) => noteToApprover;

// This is the actual note
export const setNoteToApproverText = (state, noteToApproverText = '') => noteToApproverText;

export const setSelectedCCUsers = (state, selectedCCUsers = []) =>
  selectedCCUsers.map((selectedCCUser) => ({
    value: selectedCCUser.id,
    name: selectedCCUser.text
  }));

export const setDescriptionOfAchievementText = (state, descriptionOfAchievementText = '') => descriptionOfAchievementText;

export const setBoiText = (state, boiText = '') => boiText;

export const setThankYouNoteText = (state, thankYouNoteText = '') => thankYouNoteText;

export const setSelectedCorporateValueECard = (state, selectedCorporateValueECard = {}) => selectedCorporateValueECard;

export const setSelectedCorporateValueNomination = (state, selectedCorporateValueNomination = {}) => selectedCorporateValueNomination;

export const setAwardList = (
  state,
  {
    awardLevelId: { options = [] } = {}
  } = {}
) => options;

export const setRecommendedAwardList = (
  state,
  {
    awardLevelId: { options = [] } = {},
    eCardRecommended: { value: eCardRecommendedValue = '' } = {},
    recommendedAwardLevelId: { value: recommendedAwardLevelIdValue = '' } = {}
  } = {}
) => {
  if (eCardRecommendedValue === 'true') {
    return [
      {
        text: 'eCard',
        value: 'eCard',
        selected: true
      }
    ].concat(options);
  } else if (recommendedAwardLevelIdValue !== '') {
    return options.map((option) => {
      if (recommendedAwardLevelIdValue === option.value) {
        return { ...option, selected: true };
      }
      return option;
    });
  }
  return options;
};

export const setProxyOptions = (state, { status: { code = '' } = {}, data: { proxyOptions = [] } = {} } = {}) => {
  if (code === 200) {
    return proxyOptions;
  }
  return [
    {
      error: true
    }
  ];
};

export const setRecognizerId = (state, { data: { value = '' } = {} } = {}) => value;

export const setProgramList = (state, programList = []) => programList;

export const setSelectedProgramECard = (state, selectedProgramECard = {}) => selectedProgramECard;

export const setSelectedProgramNomination = (state, selectedProgramNomination = {}) => selectedProgramNomination;

export const setSTP = (state, stp) => stp;

export const setUpdateTranslations = (state, updateTranslation = false) => updateTranslation;

export const setOrigin = (state, { setOrigin: origin } = {}) => origin;

export const setTos = (state, { termsOfServiceChecked = false }) => termsOfServiceChecked;

export const setReset = (state, reset) => reset;

export const setGroupDetails = (state, group) => group;

export const setECardAmountList = (state, eCardAmountList = {}) => eCardAmountList;

export const setSelectedECardAmount = (state, selectedECardAmount) => selectedECardAmount;

export const setECardNotifyWhenViewed = (state, eCardNotifyWhenViewed) => eCardNotifyWhenViewed;

export const setECardNotifyManagerWhenViewed = (state, eCardNotifyManagerWhenViewed) => eCardNotifyManagerWhenViewed;

export const setECardNotifyWhenViewedChecked = (state, eCardNotifyWhenViewedChecked) => eCardNotifyWhenViewedChecked;

export const setECardNotifyManagerWhenViewedChecked = (state, eCardNotifyManagerWhenViewedChecked) => eCardNotifyManagerWhenViewedChecked;

export const setScheduleDateConfig = (state, scheduleDateConfig) => scheduleDateConfig;

export const setECardList = (state, { eProducts = {}, favorites = [] } = {}) => {
  const allECards = eProducts.options;
  const favoritedECards = allECards.filter((ecard) => (
    favorites.indexOf(ecard.eproductName) > -1
  )).map((favorite) => ({ ...favorite, isFavorited: true }));
  const nonFavoriteECards = allECards.filter((ecard) => favorites.indexOf(ecard.eproductName) === -1);
  return {
    filters: eProducts.filters,
    favorites: favoritedECards,
    others: nonFavoriteECards
  };
};

export const setSelectedECard = (state, selectedECard) => selectedECard;

export const setScheduledDeliveryDate = (state, scheduledDeliveryDate) => scheduledDeliveryDate;

export const setClientTheme = (state, clientTheme) => clientTheme;

export const setPrivacy = (state, privacy) => privacy;

export const setMinCharacterError = (state, minCharacterError) => minCharacterError;

export const setCustomerFlags = (state, customerFlags) => (
  customerFlags.reduce((flagsObj, { propertyCode, propertyValue }) => ({
    ...flagsObj,
    [propertyCode]: propertyValue
  }), {})
);

export const setECardRecommended = (state, eCardRecommended) => eCardRecommended;

export const setNomineesUpdated = (state, nomineesUpdated) => nomineesUpdated;

export const setShowPopupMessage = (state, showPopupMessage) => showPopupMessage;

export const setUseRecommendedGive = (state, useRecommendedGive) => useRecommendedGive;

const actionList = {
  INIT_EVENT: { reducer: () => true, key: 'initialized' },
  SET_GIVE_OPTIONS: { reducer: setGiveOptions, key: 'giveOptions' },
  SET_USER_INFO: { reducer: setUserInfo, key: 'userInfo' },
  ADD_APP_MESSAGE: { reducer: addMessage, key: 'messages' },
  SET_FOLLOWED_EMPLOYEES: { reducer: setFollowedEmployees, key: 'followedEmployees' },
  SET_PROGRAMS: { reducer: setPrograms, key: 'programs' },
  SET_RECOGNITION_TYPES: { reducer: setRecognitionTypes, key: 'recognitionTypes' },
  SET_RECOMMENDED_AWARD_LEVEL: { reducer: setRecommendedAwardList, key: 'recommendedAwardLevel' },
  SET_SELECTED_RECOGNITION_TYPE: {
    reducer: setSelectedRecognitionType,
    key: 'selectedRecognitionType'
  },
  SET_CLIENT_THEME: { reducer: setClientTheme, key: 'clientTheme' },
  SET_RECIPIENT_IDS: { reducer: setRecipients, key: 'recipients' },
  SET_AWARD_LEVEL: { reducer: setAwardLevel, key: 'selectedAwardLevel' },
  SET_WIZARD: { reducer: setWizard, key: 'wizard' },
  SET_WIZARD_FLOW_FINISHED: { reducer: setWizardFlowFinished, key: 'wizardFlowFinished' },
  SET_AWARD_LIST: { reducer: setAwardList, key: 'awardList' },
  SET_PROXY_OPTIONS: { reducer: setProxyOptions, key: 'proxyOptions' },
  SET_RECOGNIZER_ID: { reducer: setRecognizerId, key: 'recognizerId' },
  SET_VIEW_NUMBER: { reducer: setViewNumber, key: 'viewNumber' },
  SET_PROGRAM_LIST: { reducer: setProgramList, key: 'programList' },
  SET_SELECTED_PROGRAM_ECARD: { reducer: setSelectedProgramECard, key: 'selectedProgramECard' },
  SET_SELECTED_CORPORATE_VALUE_ECARD: { reducer: setSelectedCorporateValueECard, key: 'selectedCorporateValueECard' },
  SET_SELECTED_CORPORATE_VALUE_NOMINATION: { reducer: setSelectedCorporateValueNomination, key: 'selectedCorporateValueNomination' },
  SET_SELECTED_ECARD_AMOUNT: { reducer: setSelectedECardAmount, key: 'selectedECardAmount' },
  SET_NOTE_TO_APPROVER: { reducer: setNoteToApproverText, key: 'noteToApproverText' },
  SET_UPDATE_TRANSLATIONS: { reducer: setUpdateTranslations, key: 'updateTranslations' },
  SET_DESCRIPTION_TEXT: { reducer: setDescriptionOfAchievementText, key: 'descriptionOfAchievementText' },
  SET_BOI_TEXT: { reducer: setBoiText, key: 'boiText' },
  SET_THANK_YOU_TEXT: { reducer: setThankYouNoteText, key: 'thankYouNoteText' },
  SET_ORIGIN: { reducer: setOrigin, key: 'setOrigin' },
  SET_TOS: { reducer: setTos, key: 'termsOfServiceChecked' },
  SET_ECARD_LIST: { reducer: setECardList, key: 'eCardList' },
  SET_ECARD_NOTIFY_WHEN_VIEWED_CHECKED: { reducer: setECardNotifyWhenViewedChecked, key: 'eCardNotifyWhenViewedChecked' },
  SET_ECARD_NOTIFY_MANAGER_WHEN_VIEWED_CHECKED: { reducer: setECardNotifyManagerWhenViewedChecked, key: 'eCardNotifyManagerWhenViewedChecked' },
  SET_SCHEDULED_DELIVERY_DATE: { reducer: setScheduledDeliveryDate, key: 'scheduledDeliveryDate' },
  SET_PRIVACY: { reducer: setPrivacy, key: 'privacy' },
  SET_MIN_CHARACTER_ERROR: { reducer: setMinCharacterError, key: 'minCharacterError' },
  SET_ECARD_RECOMMENDED: { reducer: setECardRecommended, key: 'eCardRecommended' },
  SET_CUSTOMER_FLAGS: { reducer: setCustomerFlags, key: 'customerFlags' },
  SET_APPROVER_LIST: { reducer: setApproverList, key: 'approverList' },
  SET_PRESENTER_LIST: { reducer: setPresenterList, key: 'presenterList' },
  SET_GROUP_DETAILS: { reducer: setGroupDetails, key: 'groupDetails' },
  SET_CC_USERS: { reducer: setCCUsers, key: 'ccUsers' },
  SET_SHOW_POPUP_MESSAGE: { reducer: setShowPopupMessage, key: 'showPopupMessage' },
  SET_USE_RECOMMENDED_GIVE: { reducer: setUseRecommendedGive, key: 'useRecommendedGive' },
  SET_SELECTED_PROGRAM_NOMINATION: [
    {
      reducer: setSelectedProgramNomination,
      key: 'selectedProgramNomination'
    },
    {
      reducer: setWizard,
      key: 'wizard'
    },
    {
      reducer: setRecommendedAwardLevel,
      key: 'recommendedAwardLevel'
    },
    {
      reducer: setWizardFlowFinished,
      key: 'wizardFlowFinished'
    },
    {
      reducer: setECardRecommended,
      key: 'eCardRecommended'
    }
  ],
  SET_SELECTED_PROGRAM_NOMINATION_AND_AWARD_LEVEL_AND_SELECTED_RECOGNITION_TYPE: [
    {
      reducer: setSelectedProgramNomination,
      key: 'selectedProgramNomination'
    },
    {
      reducer: setWizard,
      key: 'wizard'
    },
    {
      reducer: setRecommendedAwardLevel,
      key: 'recommendedAwardLevel'
    },
    {
      reducer: setWizardFlowFinished,
      key: 'wizardFlowFinished'
    },
    {
      reducer: setECardRecommended,
      key: 'eCardRecommended'
    },
    {
      reducer: setAwardLevel,
      key: 'selectedAwardLevel'
    },
    {
      reducer: setSelectedRecognitionType,
      key: 'selectedRecognitionType'
    }
  ],
  SET_STREAMLINED_FLOW_RECOGNITION: [
    {
      reducer: setSelectedProgramNomination,
      key: 'selectedProgramNomination'
    },
    {
      reducer: setWizard,
      key: 'wizard'
    },
    {
      reducer: setRecommendedAwardLevel,
      key: 'recommendedAwardLevel'
    },
    {
      reducer: setWizardFlowFinished,
      key: 'wizardFlowFinished'
    },
    {
      reducer: setECardRecommended,
      key: 'eCardRecommended'
    },
    {
      reducer: setAwardLevel,
      key: 'selectedAwardLevel'
    },
    {
      reducer: setSelectedRecognitionType,
      key: 'selectedRecognitionType'
    },
    {
      reducer: setSelectedProgramECard,
      key: 'selectedProgramECard'
    },
    {
      reducer: setSelectedECardAmount,
      key: 'selectedECardAmount'
    }
  ],
  SET_VIEW_NUMBER_TRANSACTION_ID: [
    {
      reducer: setViewNumber,
      key: 'viewNumber'
    },
    {
      reducer: setTransactionId,
      key: 'transactionId'
    }
  ],
  SET_UPLOADED_ECARD: [
    {
      reducer: setUploadedECard,
      key: 'uploadedECard'
    },
    {
      reducer: setSelectedECard,
      key: 'selectedECard'
    }
  ],
  SET_VIEW_NUMBER_AND_SELECTED_CC_USERS: [
    {
      reducer: setViewNumber,
      key: 'viewNumber'
    },
    {
      reducer: setSelectedCCUsers,
      key: 'selectedCCUsers'
    },
    {
      reducer: setNomineesUpdated,
      key: 'nomineesUpdated'
    }
  ],
  SET_GROUP_PROGRAM_DETAILS_VIEW: [
    {
      reducer: setViewNumber,
      key: 'viewNumber'
    },
    {
      reducer: setSelectedRecognitionType,
      key: 'selectedRecognitionType'
    }

  ],
  SET_APPROVER_ETC: [
    {
      reducer: setCorporateValueList,
      key: 'corporateValueList'
    },
    {
      reducer: setCCUsers,
      key: 'ccUsers'
    },
    {
      reducer: setApproverList,
      key: 'approverList'
    },
    {
      reducer: setPresenterList,
      key: 'presenterList'
    },
    {
      reducer: setDescriptionOfAchievement,
      key: 'descriptionOfAchievement'
    },
    {
      reducer: setThankYouNote,
      key: 'thankYouNote'
    },
    {
      reducer: setNoteToApprover,
      key: 'noteToApprover'
    },
    {
      reducer: setBoiCost,
      key: 'boiLabelLine1OverrideField'
    },
    {
      reducer: setPrivateEnabledNomination,
      key: 'isPrivacyEnabledNomination'
    }
  ],
  SET_WIZARD_AND_AWARD_LIST: [
    {
      reducer: setWizard,
      key: 'wizard'
    },
    {
      reducer: setAwardList,
      key: 'awardList'
    }
  ],
  SET_AWARD_LIST_AND_ECARD_RECOMMENDED_AND_WIZ_FLOW_FINISHED_AND_RECOMMENDED_AWARD_LEVEL: [
    {
      reducer: setAwardList,
      key: 'awardList'
    },
    {
      reducer: setECardRecommended,
      key: 'eCardRecommended'
    },
    {
      reducer: setWizardFlowFinished,
      key: 'wizardFlowFinished'
    },
    {
      reducer: setRecommendedAwardList,
      key: 'recommendedAwardLevel'
    },
    {
      reducer: setAwardLevel,
      key: 'selectedAwardLevel'
    }
  ],
  SET_ECARD_RECOMMENDED_AND_WIZ_FLOW_FINISHED_AND_RECOMMENDED_AWARD_LEVEL: [
    {
      reducer: setECardRecommended,
      key: 'eCardRecommended'
    },
    {
      reducer: setWizardFlowFinished,
      key: 'wizardFlowFinished'
    },
    {
      reducer: setRecommendedAwardList,
      key: 'recommendedAwardLevel'
    },
    {
      reducer: setAwardLevel,
      key: 'selectedAwardLevel'
    }
  ],
  SET_RECOGNIZER_ID_RECIPIENTS_ID_SELECTED_RECOGNITION_TYPE_TRANSACTION_ID: [
    {
      reducer: setRecognizerId,
      key: 'recognizerId'
    },
    {
      reducer: setRecipients,
      key: 'recipients'
    },
    {
      reducer: setSelectedRecognitionType,
      key: 'selectedRecognitionType'
    },
    {
      reducer: setViewNumber,
      key: 'viewNumber'
    },
    {
      reducer: setWizardUsed,
      key: 'wizardUsed'
    },
    {
      reducer: setNomineesUpdated,
      key: 'nomineesUpdated'
    },
    {
      reducer: setTransactionId,
      key: 'transactionId'
    }
  ],
  SET_RECOGNIZER_ID_AND_RECIPIENTS_ID_AND_SELECTED_RECOGNITION_TYPE_AND_TRANSACTION_ID: [
    {
      reducer: setRecognizerId,
      key: 'recognizerId'
    },
    {
      reducer: setRecipients,
      key: 'recipients'
    },
    {
      reducer: setSelectedRecognitionType,
      key: 'selectedRecognitionType'
    },
    {
      reducer: setViewNumber,
      key: 'viewNumber'
    },
    {
      reducer: setWizardUsed,
      key: 'wizardUsed'
    },
    {
      reducer: setNomineesUpdated,
      key: 'nomineesUpdated'
    },
    {
      reducer: setTransactionId,
      key: 'transactionId'
    }
  ],
  SET_WIZARD_AND_VIEW_NUMBER: [
    {
      reducer: setWizard,
      key: 'wizard'
    },
    {
      reducer: setViewNumber,
      key: 'viewNumber'
    },
    {
      reducer: setNomineesUpdated,
      key: 'nomineesUpdated'
    },
    {
      reducer: setWizardOptional,
      key: 'wizardOptional'
    }
  ],
  SET_PROGRAM_LIST_AND_SELECTED_PROGRAM_ECARD: [
    {
      reducer: setProgramList,
      key: 'programList'
    },
    {
      reducer: setSelectedProgramECard,
      key: 'selectedProgramECard'
    }
  ],
  SET_SELECTED_PROGRAM_ECARD_AND_SELECTED_ECARD_AMOUNT_AND_SELECTED_RECOGNITION_TYPE: [
    {
      reducer: setSelectedProgramECard,
      key: 'selectedProgramECard'
    },
    {
      reducer: setSelectedECardAmount,
      key: 'selectedECardAmount'
    },
    {
      reducer: setSelectedRecognitionType,
      key: 'selectedRecognitionType'
    }
  ],
  SET_PROGRAM_LIST_AND_SELECTED_PROGRAM_NOMINATION: [
    {
      reducer: setProgramList,
      key: 'programList'
    },
    {
      reducer: setSelectedProgramNomination,
      key: 'selectedProgramNomination'
    }
  ],
  SET_USER_INFO_AND_RECOGNIER_ID_AND_STP: [
    {
      reducer: setUserInfo,
      key: 'userInfo'
    },
    {
      reducer: setRecognizerId,
      key: 'recognizerId'
    },
    {
      reducer: setSTP,
      key: 'stp'
    }
  ],
  SET_ECARD_CONFIG: [
    {
      reducer: setCorporateValueList,
      key: 'corporateValueList'
    },
    {
      reducer: setECardAmountList,
      key: 'eCardAmountList'
    },
    {
      reducer: setDescriptionOfAchievement,
      key: 'descriptionOfAchievement'
    },
    {
      reducer: setECardNotifyWhenViewed,
      key: 'eCardNotifyWhenViewed'
    },
    {
      reducer: setECardNotifyManagerWhenViewed,
      key: 'eCardNotifyManagerWhenViewed'
    },
    {
      reducer: setScheduleDateConfig,
      key: 'scheduleDateConfig'
    },
    {
      reducer: setCCUsers,
      key: 'ccUsers'
    },
    {
      reducer: setDateFormat,
      key: 'dateFormat'
    },
    {
      reducer: setPrivateEnabledECard,
      key: 'isPrivacyEnabledECard'
    },
    {
      reducer: setECardUploadEnabled,
      key: 'isECardUploadEnabled'
    }
  ],
  SET_AWARD_LEVEL_AND_SELECTED_RECOGNITION_TYPE: [
    {
      reducer: setAwardLevel,
      key: 'selectedAwardLevel'
    },
    {
      reducer: setSelectedRecognitionType,
      key: 'selectedRecognitionType'
    },
    {
      reducer: setRecommendedAwardLevel,
      key: 'recommendedAwardLevel'
    }
  ],
  SET_VIEW_NUMBER_AND_WIZARD_USED: [
    {
      reducer: setViewNumber,
      key: 'viewNumber'
    },
    {
      reducer: setWizardUsed,
      key: 'wizardUsed'
    },
    {
      reducer: setAwardLevel,
      key: 'selectedAwardLevel'
    }
  ],
  SET_VIEW_NUMBER_AND_SELECTED_AWARD_LEVEL_AND_SET_SELECTED_RECOGNITION_TYPE: [
    {
      reducer: setViewNumber,
      key: 'viewNumber'
    },
    {
      reducer: setSelectedRecognitionType,
      key: 'selectedRecognitionType'
    },
    {
      reducer: setAwardLevel,
      key: 'selectedAwardLevel'
    }
  ],
  SET_SELECTED_ECARD_AND_VIEW: [
    {
      reducer: setViewNumber,
      key: 'viewNumber'
    },
    {
      reducer: setSelectedECard,
      key: 'selectedECard'
    },
    {
      reducer: setUploadedECard,
      key: 'uploadedECard'
    }
  ],
  SET_WIZARD_AND_WIZARD_OPTIONAL: [
    {
      reducer: setWizard,
      key: 'wizard'
    },
    {
      reducer: setWizardOptional,
      key: 'wizardOptional'
    }
  ],
  SET_VIEW_NUMBER_AND_EDIT_RECIPIENT: [
    {
      reducer: setViewNumber,
      key: 'viewNumber'
    },
    {
      reducer: setEditRecipient,
      key: 'editRecipient'
    }
  ],
  SET_RECIPIENT_IDS_AND_SET_INITIAL_RECIPIENTS: [
    {
      reducer: setRecipients,
      key: 'recipients'
    },
    {
      reducer: setInitialRecipients,
      key: 'initialRecipients'
    }
  ],
  SET_SELECTED_CORPORATE_VALUE_ECARD_AND_SELECTED_CORPORATE_VALUE_NOMINATION: [
    {
      reducer: setSelectedCorporateValueECard,
      key: 'selectedCorporateValueECard'
    },
    {
      reducer: setSelectedCorporateValueNomination,
      key: 'selectedCorporateValueNomination'
    }
  ],
  FINISH_WIZARD_AND_RETURN: [
    {
      reducer: setViewNumber,
      key: 'viewNumber'
    },
    {
      reducer: setWizardFlowFinished,
      key: 'wizardFlowFinished'
    },
    {
      reducer: setAwardLevel,
      key: 'selectedAwardLevel'
    },
    {
      reducer: setSelectedProgramNomination,
      key: 'selectedProgramNomination'
    },
    {
      reducer: setSelectedRecognitionType,
      key: 'selectedRecognitionType'
    }
  ]
};

export default function rootReducer(oldState, { type = '', payload } = {}) {
  // This is everything we keep when the user starts over
  const appreciateState = {
    clientTheme: {},
    followedEmployees: [],
    giveOptions: {},
    stp: '',
    proxyOptions: [],
    userInfo: {},
    recognizerId: '',
    customerFlags: [],
    useRecommendedGive: true
  };

  // This is everything we reset when the user starts over
  const baseState = {
    approverList: [],
    awardList: [],
    ccUsers: {},
    corporateValueList: '',
    dateFormat: '',
    descriptionOfAchievement: '',
    descriptionOfAchievementText: '',
    eCardAmountList: {},
    eCardList: {},
    eCardNotifyManagerWhenViewed: {},
    eCardNotifyManagerWhenViewedChecked: false,
    eCardNotifyWhenViewed: {},
    eCardNotifyWhenViewedChecked: false,
    eCardRecommended: false,
    editRecipient: false,
    groupDetails: {},
    initialRecipients: [],
    isECardUploadEnabled: false,
    isPrivacyEnabledECard: false,
    isPrivacyEnabledNomination: false,
    messages: [],
    nomineesUpdated: false,
    noteToApprover: {},
    noteToApproverText: '',
    presenterList: [],
    privacy: false,
    minCharacterError: false,
    programList: [],
    recipients: [],
    recognitionTypes: [],
    recommendedAwardLevel: [],
    reset: false,
    scheduleDateConfig: {},
    scheduledDeliveryDate: '',
    selectedAwardLevel: {},
    selectedCCUsers: [],
    selectedCorporateValueECard: {},
    selectedCorporateValueNomination: {},
    selectedECard: {},
    selectedECardAmount: {},
    selectedProgramECard: {},
    selectedProgramNomination: {},
    selectedRecognitionType: '',
    setOrigin: false,
    showPopupMessage: false,
    termsOfServiceChecked: false,
    thankYouNote: '',
    thankYouNoteText: '',
    boiText: '',
    boiLabelLine1OverrideField: {},
    transactionId: null,
    updateTranslations: false,
    uploadedECard: {},
    viewNumber: views.MODAL_CLOSED,
    wizard: [],
    wizardFlowFinished: false,
    wizardOptional: false,
    wizardUsed: false,
    recognizerId: getLoggedUserId(oldState)
  };

  const initialState = { ...baseState, ...appreciateState };

  if (type === 'RESET') {
    return {
      ...oldState, ...baseState, initialRecipients: getInitialRecipients(oldState), viewNumber: views.USER_SEARCH_VIEW
    };
  }

  if (type === 'RESET_TO_BUTTON') {
    return {
      ...oldState, ...baseState, initialRecipients: getInitialRecipients(oldState), recipients: [...getInitialRecipients(oldState)]
    };
  }

  const actions = actionList[type];

  if (typeof actions !== 'object') {
    console.error('Invalid action type:', type);
    return oldState;
  }
  if (Array.isArray(actions)) {
    return actions.reduce(
      (acc, action) => {
        const { reducer, key } = action;
        if (typeof reducer === 'function') {
          return { ...acc, ...{ [key]: reducer(oldState[key], payload[key]) } };
        }
        return acc;
      },
      { ...initialState, ...oldState }
    );
  }
  const { reducer, key } = actionList[type];
  let newState;
  if (typeof reducer === 'function') {
    newState = {
      [key]: reducer(oldState[key], payload)
    };
  }
  return { ...initialState, ...oldState, ...newState };
}
