import styles from './common_templates.css';

const svgIcon = `
    <svg
    focusable="false"
    height="16px"
    version="1.1"
    viewBox="0 0 16 16"
    width="16px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    y="0px"
    >
      <g fill="#D10A12">
        <path
          d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,
          5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,
          0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z"
          fill="#D10A12"
        />
      </g>
    </svg>`;

const specialInstructionTemplate = (specialInstructionsHeading, specialInstructionsBody) =>
  `<article class="${styles.specialInstructions}">
    <header>
      <h2 class="${styles.subheading} ${specialInstructionsHeading ? '' : styles.hidden}">${specialInstructionsHeading}</h2>
    </header>
    ${specialInstructionsBody}
  </article>`;

export const errorMsgTemplate = (errorMsg, actionText) =>
  `<div class="${styles.errorMsg}">
    ${errorMsg}
    ${actionText ? `<button type="button" class="${styles.errorButton}">
      ${actionText}
    </button>` : ''}
  </div>
`;

export const alertErrorMessageTemplate = (errorMsg) =>
  `<div class="${styles.errorAlertMsg}">
    ${errorMsg}
    <button type="button" class="${styles.errorButton} ${styles.errorBtnIcon}" aria-labelledby="alertId" aria-label="close" >
      ${svgIcon}
    </button>
  </div>
`;

export default specialInstructionTemplate;
