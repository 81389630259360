import descriptionViewTemplate, {
  modalHeaderSelector,
  descriptionHeadingSelector,
  detailsHeadingSelector,
  privacySelectBoxSelector,
  privacySelectBoxWrapperSelector,
  privacySelectBoxWrapperId,
  descriptionOfAchievementSelector,
  thankYouNoteSelector,
  boiCostSelector,
  submitButtonSelector,
  errorMsgSelector
} from './description_view_template';
import { errorMsgTemplate } from '../../components/common_templates/common_templates';
import { submitNomination } from '../../state/services/api';
import {
  formLockStateHandler,
  getFirstElementChild,
  removeAllHtml,
  translateSubmitErrorMessages,
  positionElement
} from '../../utils/utils';
import Button from '../../components/button/button';
import SelectBox from '../../components/select-box/select-box';
import Textbox from '../../components/textbox/textbox';
import DetailsSummary from '../../components/details_summary/details_summary';
import ModalHeader from '../../components/modal_header/modal_header';
import RecipientHeader from '../../components/recipient_header/recipient_header';
import styles from './description_view.css';
import { clientPrimaryBackground } from '../../css/client_colors.js';

export default class DescriptionView {
  modalHeader;
  customizeAwardHeader;
  thankYouComponent;
  messageComponent;
  submitting;
  submitButton;
  detailsSummary;

  static errorState = false;

  static setPrivacySettings = (dispatch) => ({ value = false } = {}) => {
    dispatch({
      type: 'SET_PRIVACY',
      payload: value
    });
  }

  static shouldEnableSubmitButton = (state) =>
    ((state.descriptionOfAchievement.required && state.descriptionOfAchievementText.trim() !== '') || !state.descriptionOfAchievement.required) &&
    ((state.thankYouNote.required && state.thankYouNoteText.trim() !== '') || !state.thankYouNote.required) &&
    ((state.boiLabelLine1OverrideField.required && state.boiText.trim() !== '') || !state.boiLabelLine1OverrideField.required);

  static errorMessageListChanged = ({ messages: { type = '', data } = {} } = {}) => type === 'updated' && Array.isArray(data);

  constructor(containerSelector) {
    if (document.querySelector(containerSelector)) {
      this.node = document.querySelector(containerSelector);
    } else {
      console.error(`${containerSelector} doesn't exist in document. Please pass a valid container selector to description_view component.`);
    }
  }

  initializeAllSubComponents() {
    this.modalHeader = new ModalHeader(modalHeaderSelector);
    this.customizeAwardHeader = new RecipientHeader(descriptionHeadingSelector);
    this.detailsSummary = new DetailsSummary(detailsHeadingSelector);
    this.descriptionComponent = new Textbox(descriptionOfAchievementSelector);
    this.thankYouComponent = new Textbox(thankYouNoteSelector);
    this.boiCostComponent = new Textbox(boiCostSelector);
    this.submitButton = new Button(submitButtonSelector);
    this.privacySelectBox = new SelectBox(privacySelectBoxSelector);
    this.errorMsg = this.node.querySelector(errorMsgSelector);
  }

  renderAllSubComponents(dispatch, state, translateKeyFn) {
    if (state.isPrivacyEnabledNomination === false) {
      const privacySetting = this.node.querySelector(privacySelectBoxWrapperSelector);
      if (privacySetting) {
        privacySetting.classList.add(styles.hidden);
      }
    } else {
      this.privacySelectBox.render({
        data: [
          { text: translateKeyFn('give-widget-privacy-everyone', 'Yes'), value: false },
          { text: translateKeyFn('give-widget-privacy-managers', 'No'), value: true }
        ],
        selectedValue: false,
        onChange: DescriptionView.setPrivacySettings(dispatch),
        isHidden: !state.isPrivacyEnabledNomination,
        ariaLabelledBy: privacySelectBoxWrapperId
      });
      if (this.privacySelectBox.selectBoxButtonNode) {
        this.privacySelectBox.selectBoxButtonNode.classList.add(`${styles.privacyButton}`);
      }
    }
    this.customizeAwardHeader.render({
      recipients: state.recipients,
      selectedRecognitionType: state.selectedRecognitionType
    }, dispatch, translateKeyFn);

    this.descriptionComponent.render({
      title: `${translateKeyFn('description-of-achievement-label', 'Description of Achievement')}`,
      maxLength: state.descriptionOfAchievement.max,
      minLength: state.descriptionOfAchievement.min,
      required: state.descriptionOfAchievement.required,
      disabled: state.descriptionOfAchievement.disabled || state.descriptionOfAchievement.disabled === undefined,
      text: state.descriptionOfAchievementText,
      className: `${styles.textbox}`,
      toggleText: translateKeyFn('give-widget-add-description-of-achievement-label', 'Add description of achievement?'),
      onChange: (text) => {
        if (state.descriptionOfAchievement.min !== undefined) {
          const removeMultipleSpaces = text.replace(/\s+/g, ' ');
          if (removeMultipleSpaces.length >= state.descriptionOfAchievement.min) {
            this.descriptionComponent.removeError(dispatch);
            DescriptionView.errorState = false;
          }
        }
        dispatch({
          type: 'SET_DESCRIPTION_TEXT',
          payload: removeAllHtml(text)
        });
      },
      id: 'description-message',
      ctaDiv: `#${styles.callToActionDiv}`
    }, translateKeyFn);

    this.thankYouComponent.render({
      title: `${translateKeyFn('thank-you-note-label', 'Thank You Note')}`,
      maxLength: state.thankYouNote.max,
      required: state.thankYouNote.required,
      disabled: state.thankYouNote.disabled || state.thankYouNote.disabled === undefined,
      text: state.thankYouNoteText,
      className: `${styles.textbox}`,
      toggleText: translateKeyFn('give-widget-add-thank-you-note-label', 'Add thank you note?'),
      onChange: (text) => {
        dispatch({
          type: 'SET_THANK_YOU_TEXT',
          payload: removeAllHtml(text)
        });
      },
      id: 'description-thank-you',
      ctaDiv: `#${styles.callToActionDiv}`
    });

    this.boiCostComponent.render({
      title: state.boiLabelLine1OverrideField.title,
      maxLength: state.boiLabelLine1OverrideField.max,
      required: state.boiLabelLine1OverrideField.required,
      disabled: state.boiLabelLine1OverrideField.disabled || state.boiLabelLine1OverrideField.disabled === undefined,
      text: state.boiText,
      toggleText: state.boiLabelLine1OverrideField.title,
      className: `${styles.boiCost}`,
      onChange: (text) => {
        dispatch({
          type: 'SET_BOI_TEXT',
          payload: removeAllHtml(text)
        });
      },
      id: 'description-boi-cost',
      ctaDiv: `#${styles.callToActionDiv}`
    });

    const enabled = DescriptionView.shouldEnableSubmitButton(state);

    this.submitButton.render({
      title: translateKeyFn('submit', 'Submit'),
      isDisabled: !enabled,
      className: `${clientPrimaryBackground}`,
      onClick: (evt) => {
        this.onClickSubmit(evt, dispatch, state, translateKeyFn);
      },
      id: 'description-submit'
    });

    this.detailsSummary.render({
      selectedRecognitionType: state.selectedRecognitionType,
      selectedProgram: state.selectedProgramNomination,
      selectedCorporateValue: state.selectedCorporateValueNomination,
      selectedAwardLevel: state.selectedAwardLevel
    }, dispatch, translateKeyFn);
  }

  render(dispatch, state, translateKeyFn) {
    const descriptionViewFragment = document.createRange().createContextualFragment(descriptionViewTemplate(translateKeyFn));
    const descriptionViewTemp = this.node;
    this.node = getFirstElementChild(descriptionViewFragment);
    descriptionViewTemp.parentNode.replaceChild(this.node, descriptionViewTemp);
    this.initializeAllSubComponents();
    this.renderAllSubComponents(dispatch, state, translateKeyFn);
    positionElement(`#${styles.callToActionDiv}`);
    this.node.classList.add('give-widget-current-view');
    document.querySelector('.js-close').focus();
    if (state.minCharacterError) {
      this.descriptionComponent.addError(dispatch);
    }
  }

  update(dispatch, state, stateDiff, translateKeyFn) {
    this.submitButton.isDisabled = !DescriptionView.shouldEnableSubmitButton(state);
    if (this.errorMsg) {
      this.errorMsg.classList.add(styles.hidden);
    }
    this.submitButton.onClick = (evt) => {
      this.onClickSubmit(evt, dispatch, state, translateKeyFn);
    };
    if (DescriptionView.errorMessageListChanged(stateDiff)) {
      this.errorMsg.classList.remove(styles.hidden);
      this.errorMsg.innerHTML = errorMsgTemplate(translateSubmitErrorMessages(state.messages[state.messages.length - 1].message, translateKeyFn));
      this.submitButton.isLoading = false;
    }

    positionElement(`#${styles.callToActionDiv}`);
  }

  onClickSubmit = (event, dispatch, state, translateKeyFn) => {
    if (this.descriptionComponent.checkMinCharacter(state.descriptionOfAchievementText)) {
      this.submitButton.isDisabled = true;
      this.submitButton.isLoading = true;
      this.submitting = true;
      const formLockState = formLockStateHandler();
      formLockState.lock();
      submitNomination(dispatch, state, translateKeyFn).finally(() => {
        this.submitting = false;
        formLockState.unlock();
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.descriptionComponent.addError(dispatch);
      DescriptionView.errorState = true;
      event.preventDefault();
      event.stopPropagation();
    }
  };

  hide() {
    this.node.classList.add(styles.hidden);
    this.node.classList.remove('give-widget-current-view');
  }
}
