import prismUiCss from '@octanner/prism-ui-css/prism.css';
import styles from './textbox.css';
import { alertErrorMessageTemplate } from '../common_templates/common_templates';

export const optionalTextSelector = `div.${styles.textboxContainer} > p.${styles.optional}`;
export const textboxSelector = `div.${styles.textboxContainer} > div.${prismUiCss['psm-form__textarea']}`;
export const charCounterSelector = `div.${styles.textboxContainer} > div.${styles.helperTextContainer} > p.${prismUiCss['psm-form__max-chars']}`;
export const loadingDivSelector = `div.${styles.textboxContainer} > div.${styles.loading}.${prismUiCss['psm-form__textarea']}`;
export const textAreaSelector = `div.${styles.textboxContainer} > textarea.${prismUiCss['psm-form__textarea']}`;

const textboxTemplate = (translateKeyFn, minlength) => {
  let helperText = '';
  if (minlength) {
    helperText = `
      <div class="${styles.helperText}" id="messageHelperText">
        ${translateKeyFn ? translateKeyFn('recognition-message-min-character-hyper-text',
    'Message must have a minimum {characterCount} characters', '{characterCount}', minlength) :
    'Message must have a minimum 10 characters.'}
    </div>
    `;
  }

  const messagetext = translateKeyFn ? translateKeyFn('recognition-message-min-character-error-text',
    'Enter {characterCount} characters or more. Details make your recognition meaningful.', '{characterCount}', minlength) :
    'Enter 10 characters or more. Details make your recognition meaningful.';

  return `<section>
    <button id="textbox-toggle-button" class="${styles.hidden} ${styles.toggleButton}" type="button"></button>
    <div class="${styles.textboxContainer}" id="t1">
      <label class="${prismUiCss['psm-form__label']}" id="messageHeaderText"></label>
      <div class="${prismUiCss['psm-alert--error']} ${styles.hidden} " id="alertId" aria-live="polite" role="alert">
        ${alertErrorMessageTemplate(messagetext)}
      </div>
      <p class="${styles.optional}">(${translateKeyFn ? translateKeyFn('optional', 'Optional') : 'Optional'})</p>
      <textarea class="fs-mask ${prismUiCss['psm-form__textarea']}" autocomplete="off"></textarea>
      <div class="${styles.helperTextContainer}">
      ${helperText}
        <p class="${prismUiCss['psm-form__max-chars']} ${styles.hidden} ${styles.charCounterSelector}"></p>
      </div>
      <div class="${styles.hidden} ${styles.loading} ${prismUiCss['psm-form__textarea']}"></div>
    </div>
  </section>`;
};

export default textboxTemplate;
